<template>
	<div>
		<h2 v-focus tabindex="0">書目資訊</h2>
		<ul class="book_detail">
			<li>ISBN:{{ book.ISBN }}</li>
			<li>書名：{{ book.bookname }}</li>
			<li>作者：{{ book.author }}</li>
			<li>出版社：{{ book.house }}</li>
			<li>出版日期：{{ book.date }}</li>
			<li>裝訂冊數：{{ book.bookbinding }}</li>
			<li>版次：{{ book.order }}</li>
		</ul>
		<div>
			<template v-if="user.auth_guest">
				<button
					class="btn btn-outline-secondary btn-sm"
					@click="check_create(pk)"
				>
					借閱
				</button>
				<button
					class="btn btn-outline-secondary btn-sm"
					aria-label="閱讀(另開新分頁)"
					@click="viewBook(pk)"
				>
					閱讀
				</button>
				<button
					class="btn btn-outline-secondary btn-sm"
					@click="
						feedback_id = pk;
						feedbackShow = true;
					"
				>
					回報
				</button>
			</template>
			<button class="btn btn-outline-secondary btn-sm" @click="$router.go(-1)">
				上一頁
			</button>
		</div>
		<modal v-if="feedbackShow" @closeModal="feedbackShow = false">
			<template slot="header">
				<h4 class="modal-title">書籍 {{ feedback_id }} 錯誤內容回報</h4>
			</template>
			<template slot="body">
				<h5>回報內容:</h5>
				<textarea class="feedback" v-model="feedback_content"></textarea>
			</template>
			<template slot="footer">
				<button class="btn btn-outline-dark" @click="$refs[id].close()">
					取消
				</button>
				<button class="btn btn-primary" @click="feedback()">回報</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import modal from "@/components/popup/modal.vue";
import {
	bookInfoRest,
	bookAction,
	libraryRecordAction
} from "@/api/ebookSystem.js";

export default {
	components: {
		modal
	},
	data() {
		return {
			pk: 0,
			book: {
				book_info: {}
			},
			feedbackShow: false,
			feedback_id: "",
			feedback_content: ""
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	mounted() {
		this.pk = this.$route.params.id;
		this.getData();
	},
	methods: {
		getData() {
			bookInfoRest
				.read(this.pk)
				.then(res => {
					this.book = res.data;
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		check_create(ISBN) {
			libraryRecordAction
				.checkCreate({ ISBN })
				.then(res => {
					return libraryRecordAction.checkInout({
						pk: res.data.id,
						action: "check_out"
					});
				})
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功借閱書籍" });
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		feedback() {
			bookAction
				.feedback(this.feedback_id, {
					content: this.feedback_content
				})
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功回報資料" });
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		viewBook(pk) {
			/* this.$router.push({
				name: 'bookView',
				params: {id: pk},
			});*/
			window.open(
				this.$router.resolve({ name: "bookView", params: { id: pk } }).href,
				"_blank"
			);
		}
	}
};
</script>

<style lang="scss" scoped>
.warning-dont-spread {
	color: $color-soft-red;
	margin-bottom: 1rem;
	// font-size: 16px;
}
.book_detail {
	padding: 0;
}
.book_detail li {
	padding-top: 20px;
}
</style>
